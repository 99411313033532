@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

body p {
    margin-bottom:10px;
}

nav a.active, form a, .grid a {
    @apply text-primary;
}

nav a:hover, form a:hover, .grid a:hover{
    @apply text-emerald-300;
}

a.multap-btn, button.multap-btn {
    @apply text-center block xl:inline-block mx-auto bg-primary text-white px-3 py-2 my-4;
}

a.multap-btn:hover, button.multap-btn:hover{
    @apply bg-emerald-300 text-white;
}

form#contact-form span.inputError {
    @apply text-xs text-red-600 ml-3;
}
